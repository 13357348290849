import React, {
  memo,
  Fragment,
  useMemo,
  useCallback,
  CSSProperties
} from 'react';
import filter from 'lodash/filter';

import { IsFetched, ErrorMessage, ClassName } from '../../../../../types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../productsSets/productsSetsTypes';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction,
  OnSelectedProductsSidebarOpenAction,
  OnSelectedProductsSidebarCloseAction,
  OnProductAiSearchAction,
  ProductNanoID,
  FetchProductsFilters,
  ChangeProductsFiltersFunc,
  OnProductSimilarSearchAction
} from '../../../productsTypes';

import { ProductsListItem } from '../ProductsListItem';

import { ProductsListItemLightboxBottomButtons } from './components/ProductsListItemLightboxBottomButtons';
import { ProductsListItemLightboxTitle } from './components/ProductsListItemLightboxTitle';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';
import {
  LightboxToggleBackdrop,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';
import { LoadingGridSkeleton } from '../../../../../helpers/LoadingGridSkeleton';

import { Files } from '../../../../../utils/Files';

import {
  ProductsListProducts,
  ProductsListProduct,
  ProductsListProductImages,
  ProductsListProductsSet,
  ProductsListOnProductsSelect
} from './ProductsList.types';
import ProductsCompactIndexTable from '../../tables/ProductsIndexTable/ProductsIndexTable';

interface ProductsListDefaultProps {
  cellMinSize?: number;
  changeProductsFilters?: ChangeProductsFiltersFunc;
  className?: ClassName;
  companyNanoId?: TeamNanoID;
  isGridView?: boolean;
  isMyLibrary?: boolean;
  itemClassName?: ClassName;
  loadingGridSkeletonItemsCount?: number;
  onProductAiSearch?: OnProductAiSearchAction;
  onProductSimilarSearch?: OnProductSimilarSearchAction;
  onProductSimilarSearchFilter?: OnProductSimilarSearchAction;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
  products: ProductsListProducts;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsError: ErrorMessage;
  productsFetched: IsFetched;
  productsFilters?: FetchProductsFilters;
  productsIsPlaceholderData: boolean;
  productsTotalCount: number;
  togglePreventModalClose?: LightboxToggleBackdrop;
  updateProductCache?: UpdateProductCacheAction<ProductsListProduct>;
  withProductPreviewLink?: boolean;
}

export interface ProductsListWithSelectProps {
  onProductsSelect?: ProductsListOnProductsSelect;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  onSelectedProductsSidebarOpen: OnSelectedProductsSidebarOpenAction;
  productsSet: ProductsListProductsSet | null;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  productsSetError: ErrorMessage;
  productsSetFetched: IsFetched;
  productsSetIsPlaceholderData: boolean;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductsListProductsSet>;
}

interface ProductsListWithoutSelectProps {
  onProductsSelect?: never;
  onSelectedProductsSidebarClose?: never;
  onSelectedProductsSidebarOpen?: never;
  productsSet?: never;
  productsSetCacheKeys?: never;
  productsSetError?: never;
  productsSetFetched?: never;
  productsSetIsPlaceholderData?: never;
  updateProductsSetCache?: never;
}

type ProductsListProps = ProductsListDefaultProps &
  (ProductsListWithSelectProps | ProductsListWithoutSelectProps);

function ProductsList({
  cellMinSize = 262,
  changeProductsFilters,
  className,
  companyNanoId,
  isGridView = true,
  isMyLibrary,
  itemClassName,
  loadingGridSkeletonItemsCount,
  onProductAiSearch,
  onProductSimilarSearch,
  onProductSimilarSearchFilter,
  onProductEditButtonMouseEnter,
  onProductsSelect,
  onSelectedProductsSidebarClose,
  onSelectedProductsSidebarOpen,
  products,
  productsCacheKeys,
  productsError,
  productsFetched,
  productsFilters,
  productsIsPlaceholderData,
  productsSet,
  productsSetCacheKeys,
  productsSetError,
  productsSetFetched,
  productsSetIsPlaceholderData,
  productsTotalCount,
  togglePreventModalClose,
  updateProductCache,
  updateProductsSetCache,
  withProductPreviewLink
}: ProductsListProps) {
  const productsInLightbox = useMemo<ProductsListProducts>(() => {
    return filter<ProductsListProduct>(
      products,
      (product) => product.image?.file && Files.isImage(product.image.file)
    );
  }, [products]);

  const lightboxItems = useMemo<ProductsListProductImages>(() => {
    return productsInLightbox.map((product) => product.image);
  }, [productsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const product = productsInLightbox[index];
      if (!product) {
        return [];
      }

      return (
        <ProductsListItemLightboxBottomButtons
          product={product}
          productsSet={productsSet}
          productsCacheKeys={productsCacheKeys}
          productsSetCacheKeys={productsSetCacheKeys}
          onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
          onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
          updateProductsSetCache={updateProductsSetCache}
          updateProductCache={updateProductCache}
        />
      );
    },
    [
      productsInLightbox,
      productsSet,
      productsCacheKeys,
      productsSetCacheKeys,
      onSelectedProductsSidebarOpen,
      onSelectedProductsSidebarClose,
      updateProductsSetCache,
      updateProductCache
    ]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const product = productsInLightbox[index];
      if (!product) {
        return null;
      }

      return <ProductsListItemLightboxTitle product={product} />;
    },
    [productsInLightbox]
  );

  const wrapperStyle = useMemo(() => {
    return {
      '--tw-grid-cell-min-size': `${cellMinSize}px`
    };
  }, [cellMinSize]);

  return (
    <Fragment>
      <AlertMessage
        addClassName="m-4"
        message={productsError || productsSetError}
      />
      <Loading
        loaded={!productsIsPlaceholderData && !productsSetIsPlaceholderData}
      />
      <LoadingGridSkeleton
        loaded={
          onSelectedProductsSidebarOpen
            ? (productsIsPlaceholderData || productsFetched) &&
              (productsSetIsPlaceholderData || productsSetFetched)
            : productsIsPlaceholderData || productsFetched
        }
        itemsCount={loadingGridSkeletonItemsCount}
      >
        {productsTotalCount === 0 && <NoResults addErrorClassName="m-4" />}
        {productsTotalCount > 0 && (
          <Fragment>
            {isGridView ? (
              <div
                className={
                  className ||
                  'px-2 sm:px-4 grid grid-cols-auto-fill gap-2 sm:gap-4'
                }
                style={wrapperStyle as CSSProperties}
              >
                {products.map((product) => (
                  <ProductsListItem
                    className={itemClassName}
                    companyNanoId={companyNanoId}
                    isMyLibrary={isMyLibrary}
                    key={product.uuid}
                    product={product}
                    productsSet={productsSet}
                    productsCacheKeys={productsCacheKeys}
                    productsSetCacheKeys={productsSetCacheKeys}
                    productsFilters={productsFilters}
                    changeProductsFilters={changeProductsFilters}
                    onLightboxOpen={handleLightboxOpenOnSlide}
                    onSelectedProductsSidebarOpen={
                      onSelectedProductsSidebarOpen
                    }
                    onSelectedProductsSidebarClose={
                      onSelectedProductsSidebarClose
                    }
                    onProductAiSearch={onProductAiSearch}
                    onProductSimilarSearch={onProductSimilarSearch}
                    onProductSimilarSearchFilter={onProductSimilarSearchFilter}
                    updateProductsSetCache={updateProductsSetCache}
                    updateProductCache={updateProductCache}
                    onProductsSelect={onProductsSelect}
                    onProductEditButtonMouseEnter={
                      onProductEditButtonMouseEnter
                    }
                    withProductPreviewLink={withProductPreviewLink}
                  />
                ))}
              </div>
            ) : (
              <ProductsCompactIndexTable
                companyNanoId={companyNanoId}
                isMyLibrary={isMyLibrary}
                products={products}
                productsSet={productsSet}
                productsCacheKeys={productsCacheKeys}
                productsSetCacheKeys={productsSetCacheKeys}
                productsFilters={productsFilters}
                changeProductsFilters={changeProductsFilters}
                onLightboxOpen={handleLightboxOpenOnSlide}
                onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
                onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
                onProductAiSearch={onProductAiSearch}
                onProductSimilarSearch={onProductSimilarSearch}
                onProductSimilarSearchFilter={onProductSimilarSearchFilter}
                updateProductsSetCache={updateProductsSetCache}
                updateProductCache={updateProductCache}
                onProductsSelect={onProductsSelect}
                onProductEditButtonMouseEnter={onProductEditButtonMouseEnter}
              />
            )}
          </Fragment>
        )}
      </LoadingGridSkeleton>
      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<ProductsListProps>(ProductsList);
